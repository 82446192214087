import api from '@/libs/api'
import helper from './helper'

export default {
  mixins: [helper],
  methods: {
    clearUserData() {
      this.$store.commit('auth/updateToken', null)
      this.$store.commit('auth/updateExpiry', null)
      this.$store.commit('auth/updateAccountInfo', {})
      this.$store.commit('auth/updatePermissions', [])
      this.$store.commit('auth/updateRoleStatus', false)
      this.$store.commit('auth/updateAbilities', [])
    },
    isUserValid() {
      return api.getData('account_management/user_account/', true)
        .then(response => response.data.status === 200).catch(() => false)
    },
    userLoggedIn(store) {
      if (store) {
        return store.getters['auth/userLoggedIn']
      }
      return this.$store.getters['auth/userLoggedIn']
    },
    setUserData(response) {
      this.$store.commit('auth/updateToken', `Token ${response.token}`)
      this.$store.commit('auth/updateExpiry', response.expiry)
      this.$store.commit('auth/updatePermissions', response.user.groups)
      this.$store.commit('auth/updateAccountInfo', response.user.account_info)
      this.getPermissionList()
      this.$router.replace('/').then(() => {
        this.notificationMessage('success', 'CoffeeIcon', 'Welcome to Go Zayaan Corporate Admin', 'You have successfully logged in. Now you can start to explore!')
      })
    },
    getPermissionList() {
      api.getData('/auth/group_permissions_list/', true).then(response => {
        if (response.data.status) {
          const abilities = []
          const modules = response.data.result
          modules.forEach(name => {
            const modifyType = []
            if (name.view) {
              modifyType.push('view')
            }
            if (name.create) {
              modifyType.push('create')
            }
            if (name.update) {
              modifyType.push('update')
            }
            if (name.delete) {
              modifyType.push('delete')
            }
            abilities.push({
              action: modifyType,
              subject: name.module,
              conditions: {
                download_btn: true,
              },
            })
          })
          // this.$store.commit('auth/updateAbilities', abilities)
          // this.$ability.update(abilities)
        }
      }).catch()
    },
  },
}
